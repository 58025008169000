<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Meine Leistungen
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege fest welche Arten der Planung du anbietest.
        </v-tooltip>
      </h4>
    </v-col>
    <v-col >
      <div v-for="product of myProducts" :key="product.id" class="pb-3">
        {{ product.name }}
        <v-tooltip right color="red" >
          <template v-slot:activator="{ on, attrs }">
            <spanv
                v-bind="attrs"
                v-on="on"
            >
              <v-icon @click="sureToDeleteDialog = true; selectedId = product.id">
                mdi-delete
              </v-icon>
            </spanv>
          </template>
          Leistung löschen
        </v-tooltip>

      </div>
      <SureToDelete
          class="pt-0 mt-0 pb-0 mb-0"
          :dialog="sureToDeleteDialog"
          :text="'Bist du Sicher, dass du den Status löschen möchtest? Bitte prüfe zunächst, ob sich noch Hochzeiten in diesem Status befinden.'"
          @cancel="sureToDeleteDialog=false"
          @sure="deleteStatus(); sureToDeleteDialog=false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="5" md="4">
          <v-text-field label="Neue Leistung"  v-model="newMyProduct"></v-text-field>
        </v-col>
        <v-col cols="3" md="2">
          <v-text-field type="number" label="Preis in €" v-model="newMyProductPrice"></v-text-field>
        </v-col>
        <v-col cols="4" md="6">
          <a  @click="addMyProducts(newMyProduct)" class="d-inline">
            Hinzufügen
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {dotToKomma, error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "MyProducts",
  computed: {
    ...mapGetters('settings', {
      myProducts: 'myProducts',
    }),
  },
  components:{
    Message,
    SureToDelete
  },
  props:{
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/getMyProducts', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data(){
    return {
      newMyProduct: '',
      newMyProductPrice: 0,
      message: msgObj(),
      sureToDeleteDialog:false,
      selectedId:""
    }
  },

  methods: {
    deleteStatus() {
      this.$store.dispatch('settings/deleteMyProduct', {
        uid: this.user.id,
        id: this.selectedId,
      }).then(() => {
        this.message = success('Status erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
        setTimeout(() => this.message.show = false, 5000);
      })
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    addMyProducts(name) {
      if (name !== '') {
        this.$store.dispatch('settings/addMyProduct', {
          uid: this.user.id,
          name,
          price: this.newMyProductPrice
        }).then(() => {
          this.newMyProduct = ''
          this.newMyProductPrice = 0
          this.message = success('Feld erfolgreich angelegt.')
        }).catch((err) => {
          this.message = error(err)
        })
      }
    },
  }
}
</script>
